import React, { useEffect, useState } from 'react'
import { withFirebase } from '../Firebase'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Login } from '../Login'

const contactBackgroundClass = 'contact-bg'

const Contact = ({ firebase }) => {
    
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        document.body.classList.add(contactBackgroundClass)
        return () => {
            document.body.classList.remove(contactBackgroundClass)
        }
    })

    const onSend = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        await firebase.createContact({ name, email, phoneNumber, message })
        
        // TODO: Use a Success screen...
        alert('Your message was successfully sent!')
        setName('')
        setEmail('')
        setPhoneNumber('')
        setMessage('')

        setIsLoading(false)
    }

    return (
        <>
            <Header />
            <section className="contact-main">
                <div className="row">
                    <div className="container">
                        <div className="col-md-4 col-sm-4 contact-left">
                            <h1>We’d love<br />to talk.</h1>
                        </div>
                        <div className="col-md-8 col-sm-8">
                            <div className="contact-form">
                                <form method="post" action="/contact">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="name" 
                                            placeholder="Name"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            placeholder="Phone Number"
                                            value={phoneNumber}
                                            onChange={e => setPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            name="message"
                                            placeholder="Message"
                                            value={message}
                                            onChange={e => setMessage(e.target.value)}
                                        >
                                        </textarea>
                                    </div>
                                    <button 
                                        type="button" 
                                        className="btn btn-primary"
                                        onClick={onSend}
                                    >
                                        <span style={ isLoading ? {} : { display: 'none' }} className="glyphicon glyphicon-refresh spinning" /> Send
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default withFirebase(Contact)
