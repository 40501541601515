import React from 'react'

const ios = 'https://apps.apple.com/ec/app/my-free-agent/id1508054721?l=en';
const android = 'https://play.google.com/store/apps/details?id=com.us.myfree.agent&hl=en_US&gl=US';

export default () => 
    <div className="footer">
        <div className="container row" style={{ height: 'auto', margin: 'auto' }}>
            <span
                class="col-sm"
                style={{
                    display: 'flex',
                    flexShrink: 1,
                }}
            >
                <p style={{ margin: 'auto' }}>2022 Claimkey. All Rights Reserved</p>
            </span>
            <span
                class="col-sm"
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'center'
                }}
            >
                <a style={{ margin: 8 }} href={ios}>
                    <img
                        src="/images/app-store.png"
                        width={150}
                    />
                </a>
                <a style={{ margin: 8 }} href={android}>
                    <img
                        src="/images/google-play.png"
                        width={150}
                    />
                </a>
			</span>
            <span
                class="col-sm"
                style={{
                    display: 'flex',
                    flexShrink: 1,
                }}
            >
                <ul>
                    <li><a href="https://twitter.com/Freeagentapp1" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/freeagent.app/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.facebook.com/FREEAGENTAPP1/?modal=admin_todo_tour" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
              </ul>
            </span>
        </div>
    </div>
