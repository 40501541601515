import React, { useState } from 'react'
import {
    useHistory,
} from "react-router-dom"
import { withFirebase } from '../Firebase'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Login } from '../Login'

const Home = ({ firebase, ...props }) => {

    // console.log(props)

    const history = useHistory()
    const [id, setId] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSearch = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        try {
            const claim = await firebase.searchClaims({ id })
            history.push("/report", { claims: [claim] })
        } catch (error) {
            alert(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Header />
            <section className="landing-main">
                <div className="row">
                    <div className="container">
                        <h1>
                            Claimkey provides automated property damage reports delivered by mobile app and web software. The property damage reports drive efficiencies in the indemnification investigations of insurance company claims.
                        </h1>
                        <div className="landing-form">
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="search[]"
                                    className="form-control"
                                    value={id}
                                    onChange={e => setId(e.target.value)}
                                    placeholder="Enter report number"
                                />
                                <span className="input-group-btn">
                                    <button 
                                        type="button" 
                                        className="btn btn-primary"
                                        disabled={isLoading}
                                        onClick={handleSearch}
                                    >
                                        <span style={ isLoading ? {} : { display: 'none' }} className="glyphicon glyphicon-refresh spinning" /> Purchase Report
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default withFirebase(Home)
