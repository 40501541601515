import React from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer'

export default () => 
    <>
        <Header />
        <div className="team-banner-top about-top-banner">
            <div className="container">
                <h1>About</h1>
            </div>
        </div>
        <section className="team-main">
            <div className="container">
                <div className="row">
                    <div className="about-content">
                        <p>Claimkey was formed after years of extensive research into the automotive repair, as well as feedback from multiple adjusters in the insurance industry. We learned that the process of insurance reporting, not only lacks in speed for resolving a claim in a timely manor, but also on the spot, key information needed to resolve the incidents is loss as well. This became our unique mission statement for Claimkey: To bridge the gap between Insurance Agencies by letting the consumers tell their side of the story.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    </>