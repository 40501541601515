import React, { useState } from 'react'
import {
    useHistory,
} from "react-router-dom"
import { withFirebase } from '../Firebase'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Claim } from '../Claim'

const Report = ({ firebase }) => {
    
    const history = useHistory()
    const [id, setId] = useState('')
    const [claims, setClaims] = useState(history.location.state?.claims ?? [])
    // useEffect(() => {
    //     setClaims(history.location.state.reports)
    // }, [])
    // console.log(history)

    const handleSearch = async (event) => {
        event.preventDefault()
        try {
            const claim = await firebase.searchClaims({ id })
            setClaims(claims.concat([claim]))
        } catch (error) {
            alert(error)
        }
    }

    const onPurchase = ({ claim }) => {
        history.push("/payment", { claims: [claim] })
    }

    return (
        <>
            <Header theme="WHITE" />
            <section className="signup-main">
                <div className="row">
                    <div className="container">
                        <div className="col-md-12 col-sm-12">
                            <h2>Report</h2>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="search-report">
                                {/* <form method="POST" action="/report"> */}
                                    <div className="input-group">
                                        {/* <input type="hidden" name="_token" value="KRt5KeSxvi410M4iR831B204UeF633vOluQydZfP" /> */}
                                        {/* <input type="hidden" name="search[]" value="20A78177-2C99-4C7F-90BF-B53934F73F7F" className="form-control" placeholder="Add another report number" />
                                        <input type="hidden" name="search[]" value="53BD29F0-5A22-4906-8B78-27DDB3DD7959" className="form-control" placeholder="Add another report number" /> */}
                                        <input
                                            type="text"
                                            name="search[]"
                                            className="form-control"
                                            value={id}
                                            onChange={e => setId(e.target.value)}
                                            placeholder="Add another report number"
                                        />

                                        {/* <input type="text" name="search[]" className="form-control" placeholder="Add another report number" /> */}
                                        <span className="input-group-btn">
                                            {/* <input type="submit" name="submit" value="Add" className="btn btn-default" /> */}
                                            <input
                                                type="submit"
                                                name="submit"
                                                value="Add"
                                                // value="Purchase Report"
                                                className="btn btn-default"
                                                onClick={handleSearch}
                                            />
                                        </span>
                                    </div>
                                {/* </form> */}
                            </div>
                        </div>
                        <div className="claim-main">
                            { claims.map(claim => <Claim key={claim.id} claim={claim} onPurchase={onPurchase} />) }
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default withFirebase(Report)
