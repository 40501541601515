import React from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer'

export default () => 
    <>
        <Header />
        <div className="team-banner-top">
            <div className="container">
                <h1>We are dedicated professionals, assisting clients with streamlining their claims with Insurance Agencies.</h1>
            </div>
        </div>
        <section className="team-main">
            <div className="container">
                <div className="row" style={{ marginBottom: 80 }}>
                    <div className="col-md-6 col-sm-6">
                        <div className="team-box">
                            <div className="team-img">
                                <img src="/images/team1.png" width={100} height={100} />
                            </div>
                            <h4>VINCENT BRADLEY</h4>
                            <span>CEO</span>
                            <p>Vincent Bradley, Founder of the Claimkey App is a highly detailed, driven individual that has dedicated 16 years in providing outstanding customer Service in the Auto Repair & Insurance Industry. He’s a graduate of University of Houston Downtown and is a Texas Certified All-Lines Adjuster. Understanding accidents from a client as well as an adjuster’s point of view, Vincent knows that it’s possible to go beyond the normal standards of Insurer to Customer relations with clear and concise directions to go through the filing process with efficiency for both parties. Vincent is a Houston native where he currently practices his license.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="team-box">
                            <div className="team-img">
                                <img src="/images/team2.png" width={100} height={100} />
                            </div>
                            <h4>EDDIE S. ARROYO</h4>
                            <span>CFO</span>
                            <p>Eddie is the Co-Founder and Co-CEO of the Claimkey App. Eddie leads Claimkey’s Business Development and Strategy initiatives. He has extensive experience in the Financial Advisory field. In addition, his background in Insurance and Software Engineering provides him the skills to run Claimkey’s day-to-day operations. He is a Texas Certified All-Lines Adjuster and a graduate of Louisiana State University. Also, he is the Co-Founder of the Young Professionals in Tech (pending), a 501(c)(3) non-profit organization. Eddie enjoys serving clients and has a passion for the insurance industry. He is a Houston native where he currently practices his license.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="team-box">
                            <div className="team-img">
                                <img src="/images/team-rob.png" width={100} height={100} />
                            </div>
                            <h4>ROBERT JOHNSON</h4>
                            <span>CTO</span>
                            <p>Rob started programming on a Tandy 1000 EX with the latest issue of Byte magazine by his side. Thirty years and thirty platforms later he's still at it. When desktop applications were interesting, Rob built Visual Basic and Pascal programs. Armed with his 28.8 modem he toiled in Notepad writing HTML, JS and CSS before moving to the comparitively sane world of Adobe Flash and Director. Eventually Rob decided he could find some serenity in the hot server technologies of the day: PHP and .NET. When the iPhone was released he saw a way to properly apply his Computer Science degree. For about 12 years Rob has focused on native mobile development on both iOS and Android platforms, but digs in wherever his experience can help.

When not programming for profit he applies his abilities to endlessly refactoring boring apps that will never ship or figuring out how to mod video games.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    </>