import React from 'react'
import {
    BrowserRouter as Router,
    Link,
    Switch,
    Route,
    useLocation,
} from "react-router-dom"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import Helmet from 'react-helmet';
import '../App.css'
import { withAuthentication } from './Session'
import { Home } from './Home'
import { About } from './About'
import { Team } from './Team'
import { Contact } from './Contact'
import { Testimonials } from './Testimonials'
import { Report } from './Report'
import { Payment } from './Payment'
import { Login } from './Login'
import { Claims } from './Claims'
import { Purchase } from './Purchase'
import { Privacy } from './Privacy'
// import { createBrowserHistory } from "history";

// TODO: pull based on user dev
// Test
// const stripePromise = loadStripe('pk_test_TbUaC6fOzHOTwjnXtsUxVXvx000SrOrlCn')
// Live
const stripePromise = loadStripe('pk_live_vn7DmldRaON6cu2I3wUvqFeS00YiR9K4vz')

stripePromise.then(stripe => {
    // alert('striped!')
    // var elements = stripe.elements({
    //     fonts: [
    //         {
    //             // cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400,500',
    //             src: 'https://fonts.googleapis.com/css?family=Montserrat:400,500',
    //             // family: 'Effra',
    //             // src: 'local("Effra"), local("effra"), url(https://cuddlecompanions.org/wp-content/themes/diamondphoenix/fonts/effra.ttf) format("truetype")',
    //             // unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
    //         },
    //     ]
    // });
})

const WebStyles = () => {
    return (
        <>
            <link rel="stylesheet" href="/css/bootstrap.3.4.1.min.css" />
            <link rel="stylesheet" href="/css/ladda-themeless.min.css" />
            <link rel="stylesheet" href="/css/style.css" />
        </>
    )
}

const ClaimStyles = () => {
    return (
        <>
            <link rel="stylesheet" href="/template/stylesheets-prefixed.css" />
            <link rel="stylesheet" href="/template/extra.css" />
        </>
    )
}

const App = () => {
    return (
        <>
            {/* <Helmet
                link={links}
                // onChangeClientState={(newState, addedTags, removedTags) => console.log(newState, addedTags, removedTags)}
            />   */}
            <Router>
                <Switch>
                    <Route path="/about">
                        <WebStyles />
                        <About />
                    </Route>
                    <Route path="/team">
                        <WebStyles />
                        <Team />
                    </Route>
                    <Route path="/contact">
                        <WebStyles />
                        <Contact />
                    </Route>
                    <Route path="/testimonials">
                        <WebStyles />
                        <Testimonials />
                    </Route>
                    <Route path="/report/:reportId?">
                        <WebStyles />
                        <Report />
                    </Route>
                    <Route path="/payment/:reportId?">
                        <WebStyles />
                        <Elements stripe={stripePromise}>
                            <Payment />
                        </Elements>
                    </Route>
                    <Route path="/claims/created">
                        <WebStyles />
                        <Claims created />
                    </Route>
                    <Route path="/claims/purchased">
                        <WebStyles />
                        <Claims purchased />
                    </Route>
                    <Route path="/claims/view/:claimId">
                        <ClaimStyles />
                        <Purchase />
                    </Route>
                    <Route path="/privacy">
                        <WebStyles />
                        <Privacy />
                    </Route>
                    <Route path="/">
                        <WebStyles />
                        <Home />
                    </Route>
                </Switch>
                <Login />
            </Router>
        </>
    )
}

export default withAuthentication(App)
// export default App
