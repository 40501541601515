import React from 'react'
import { DateTime } from "luxon"
import copyTextToClipboard from "../../shared/copyTextToClipboard"

const type = ({ claim }) => {
    switch (claim.claimType) {
    case 'autoWitness':
        return 'Auto Witness'
    case 'autoNonCatastrophe':
        return 'Auto Non Catastrophe'
    case 'autoEarthquakeCatastrophe':
        return 'Auto Earthquake Catastrophe'
    case 'autoFireCatastrophe':
        return 'Auto Fire Catastrophe'
    case 'autoFloodCatastrophe':
        return 'Auto Flood Catastrophe'
    case 'autoHailCatastrophe':
        return 'Auto Hail Catastrophe'
    case 'autoHurricaneCatastrophe':
        return 'Auto Hurricane Catastrophe'
    case 'autoTornadoCatastrophe':
        return 'Auto Tornado Catastrophe'
    case 'businessWitness':
        return 'Business Witness'
    case 'businessNonCatastrophe':
        return 'Business Non Catastrophe'
    case 'businessEarthquakeCatastrophe':
        return 'Business Earthquake Catastrophe'
    case 'businessFireCatastrophe':
        return 'Business Fire Catastrophe'
    case 'businessFloodCatastrophe':
        return 'Business Flood Catastrophe'
    case 'businessHailCatastrophe':
        return 'Business Hail Catastrophe'
    case 'businessHurricaneCatastrophe':
        return 'Business Hurricane Catastrophe'
    case 'businessTornadoCatastrophe':
        return 'Business Tornado Catastrophe'
    case 'homeWitness':
        return 'Home Witness'
    case 'homeNonCatastrophe':
        return 'Home Non Catastrophe'
    case 'homeEarthquakeCatastrophe':
        return 'Home Earthquake Catastrophe'
    case 'homeFireCatastrophe':
        return 'Home Fire Catastrophe'
    case 'homeFloodCatastrophe':
        return 'Home Flood Catastrophe'
    case 'homeHailCatastrophe':
        return 'Home Hail Catastrophe'
    case 'homeHurricaneCatastrophe':
        return 'Home Hurricane Catastrophe'
    case 'homeTornadoCatastrophe':
        return 'Home Tornado Catastrophe'
    default:
        return ''
    }
}

const image = ({ claim }) => {
    switch (true) {
    case claim.claimType.startsWith('auto'):
        return '/images/car-img.png';
    case claim.claimType.startsWith('home'):
        return '/images/home-img.png'
    case claim.claimType.startsWith('business'):
        return '/images/business-img.png'
    default:
        return ''
    }
}

export default ({ claim, onPurchase }) => {

    const handlePurchase = (event) => {
        event.preventDefault()
        onPurchase({ claim })
    }

    const onCopy = (event) => {
        event.preventDefault()
        copyTextToClipboard(`${window.location.origin}/report/${claim.id}`)
    }

    return (
        <div className="col-md-4 col-sm-4">
            <div className="claim-box">
                <div className="claim-img">
                    <img src={image({ claim })} alt="Automotive Claim" />
                </div>
                <h4>{type({ claim })}</h4>
                <p>
                    Date : {DateTime.fromSeconds(claim.date.seconds || claim.date._seconds || 0).toFormat('MMMM dd, yyyy')}<br />
                    Status : Available for purchase<br />
                    Report # to share
                </p>
                <div className="claim-code">
                    <span>{claim.id}</span>
                    <a onClick={onCopy}><img src="/images/code-copy.png" /></a>
                </div>
                { onPurchase && 
                    <a 
                        className="btn btn-default"
                        onClick={handlePurchase}
                    >
                        Purchase
                    </a>
                }
            </div>
        </div>
    )
}
