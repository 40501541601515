// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/

import Firebase from './firebase'
import FirebaseContext, { withFirebase } from './context'

export default Firebase

export {
    FirebaseContext,
    withFirebase
}
