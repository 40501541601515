import React, { useContext } from 'react'
import {
    Link
} from "react-router-dom"
import { AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'

const themes = {
    DEFAULT: {
        headerClass: '',
        logo: '/images/logo_white.png',
    },
    WHITE: {
        headerClass: 'inner-header1',
        logo: '/images/logo_black.png',
    }
}

const Header = ({ theme = "DEFAULT", firebase }) => {

    const authUser = useContext(AuthUserContext)

    const handleLogout = async (event) => {
        event.preventDefault()
        try {
            await firebase.auth.signOut()
        } catch (error) {
            alert(error)
        }
    }

    return (
        <header className={`header-main ${themes[theme].headerClass}`}>
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-default">
                        <div className="nav-top">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a className="navbar-brand" href="/"><img src={themes[theme].logo} alt="Claimkey Logo" /></a>
                            </div>
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav navbar-right">
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/team">Our Team</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/testimonials">Testimonials</Link></li>
                                    { authUser && (authUser.isAnonymous === false)
                                        ? (
                                            <li className="dropdown">
                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                    <img src="/images/user-ic.png" /> {authUser.email} <span className="caret"></span>
                                                </a>
                                                <ul className="dropdown-menu">
                                                    <li><Link to="/claims/created"><img src="/images/p-clam.png" /> Created Claims</Link></li>
                                                    <li><Link to="/claims/purchased"><img src="/images/p-clam.png" /> Purchased Reports</Link></li>
                                                    {/* <li><a href="/claims/created"></li>
                                                    <li><a href="/claims/purchased"></a></li> */}
                                                    {/* <li><a href="#"><img src="/images/profile.png" /> Profile</a></li> */}
                                                    <li><a onClick={handleLogout}><img src="/images/logout.png" /> Logout</a></li>
                                                </ul>
                                            </li>
                                        ) : (
                                            <li>
                                                <div className="login-btn">
                                                    <a href="#" data-toggle="modal" data-target="#loginModal">
                                                        Log In / Sign up
                                                    </a>
                                                </div>
                                            </li> 
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

// export default withAuthentication(Header)
export default withFirebase(Header)
// export default Header
