import React, { useContext, useEffect, useState } from 'react'
import { withFirebase } from '../Firebase'
import {
    useHistory,
} from "react-router-dom"
import { AuthUserContext } from '../Session'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Claim } from '../Claim'

const Purchase = withFirebase(({ firebase, purchase }) => {
    const history = useHistory()

    const onDownload = async () => {
        // TODO: Start the download, for now, email
        await firebase.download({ purchase })
        alert('This report has been sent to you via email.')
    }

    const onView = async () => {
        // TODO: Start the download, for now, email
        // await firebase.download({ purchase })
        // alert('This report has been sent to you via email.')

        // event.preventDefault()
        // setIsLoading(true)
        try {
            // const claim = await firebase.searchClaims({ id })
            // const claim = await firebase.load({ purchase })
            // history.push("/claims/view", { claim })
            history.push(`/claims/view/${purchase.claims[0]}`)
            // console.log('purchase', purchase)
        } catch (error) {
            alert(error)
        } finally {
            // setIsLoading(false)
        }

    }

    const view = (
        <span
            style={{ flexShrink: 0, marginLeft: 16, cursor: 'pointer' }} onClick={onView}
        >
            <span
                className="glyphicon glyphicon-save-file"
            /> View</span>
    )

    const download = (
        <span
            style={{ flexShrink: 0, marginLeft: 16, cursor: 'pointer' }} onClick={onDownload}
        >
            <span
                className="glyphicon glyphicon-save-file"
            /> Download</span>
    )

    return (
        <div
            className="purchase-row"
            // onClick={onDownload} 
        >
            <span style={{ flexGrow: 1 }}>{purchase.claims[0]}</span>
            {view}
            {download}
        </div>
    )
})

const Claims = ({ created = false, purchased = false, firebase }) => {

    const authUser = useContext(AuthUserContext)
    const [claims, setClaims] = useState([])
    const [purchases, setPurchases] = useState([])

    useEffect(() => {
        const loadClaims = async (authUser) => {
            setClaims([])
            try {
                if (created) {
                    const claims = await firebase.createdClaims(authUser)
                    setClaims(claims)
                } else {
                    const purchases = await firebase.purchasedClaims(authUser)
                    setPurchases(purchases)
                }
            } catch (err) {
                console.error(err)
            }
        }

        if (authUser) {
            loadClaims(authUser)
        } else {
            setClaims([])
        }

    }, [authUser, created, purchased])

    return (
        <>
            <Header theme="WHITE" />
            <section className="signup-main">
                <div className="row">
                    <div className="container">
                        <div className="col-md-12 col-sm-12">
                            <h2>Your { created ? 'Created Claims' : 'Purchased Reports' }</h2>
                        </div>
                        <div className="col-md-12 col-sm-12">
                        {/* <div className="claim-main"> */}
                            { created ? 
                                claims.map(claim => <Claim key={claim.id} claim={claim} />) 
                                :
                                purchases
                                    .filter(purchase => purchase.status === 'success')
                                    .map(purchase => <Purchase key={purchase.id} purchase={purchase} />)
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default withFirebase(Claims)
