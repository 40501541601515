import React, { useRef, useState } from 'react'
import { withFirebase } from '../Firebase'

const Mode = {
    LOGIN: 'LOGIN',
    FORGOTPASSWORD: 'FORGOTPASSWORD',
    REGISTER: 'REGISTER',
}

const LoginForm = withFirebase(({ navigate, firebase, onLogin }) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleLogin = async (event) => {
        event.preventDefault()

        setIsLoading(true)
        try {
            await firebase.auth.signInWithEmailAndPassword(email, password)
            onLogin()
        } catch (error) {
            alert(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="modal-content login-form">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="myModalLabel">Login</h4>
            </div>
            <div className="modal-body">
                <div className="login-main">
                    <form>
                        <div className="form-group">
                            <input 
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="Password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <button 
                            type="button" 
                            className="btn btn-primary"
                            onClick={handleLogin}
                            disabled={isLoading}
                        >
                            <span style={ isLoading ? {} : { display: 'none' }} className="glyphicon glyphicon-refresh spinning" /> Login
                        </button>
                        <p><a className="Forgot" onClick={ () => navigate(Mode.FORGOTPASSWORD) }>Forgot Password?</a></p>
                        <p>Not a member yet? <a className="Register" onClick={ () => navigate(Mode.REGISTER) }>Register Now</a></p>
                    </form>
                </div>
            </div>
        </div>
    )
})

const ForgotPasswordForm = withFirebase(({ navigate, firebase }) => {

    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleForgotPassword = async (event) => {
        event.preventDefault()
        
        setIsLoading(true)
        try {
            await firebase.auth.sendPasswordResetEmail(email)
            navigate(Mode.LOGIN)
        } catch (error) {
            alert(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="modal-content Forgot-form">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="myModalLabel">Forgot Password</h4>
            </div>
            <div className="modal-body">
                <div className="Forgot-form-main">
                    <p>Enter your email address and we will send you a link to reset your password.</p>
                    <form>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <button 
                            type="button" 
                            className="btn btn-primary"
                            onClick={handleForgotPassword}
                            disabled={isLoading}
                        >
                            <span style={ isLoading ? {} : { display: 'none' }} className="glyphicon glyphicon-refresh spinning" /> Forgot Password
                        </button>

                        <p><a className="back" onClick={ () => navigate(Mode.LOGIN) }>Back</a></p>
                    </form>
                </div>
            </div>
        </div>
    )
})

const RegisterForm = withFirebase(({ navigate, firebase, onRegister }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleRegister = async (event) => {
        event.preventDefault()

        setIsLoading(true)
        try {
            const userInfo = await firebase.auth.createUserWithEmailAndPassword(email, password)
            await firebase.createUser({ userInfo, name })

            onRegister()
        } catch (error) {
            alert(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="modal-content register-form">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="myModalLabel">Registration</h4>
            </div>
            <div className="modal-body">
                <div className="Forgot-form-main">
                    <p></p>
                    <form id="registerForm1" autoComplete="off">
                        <div className="form-group">
                            <input
                                type="name"
                                name="name"
                                className="form-control"
                                placeholder="Name"
                                required
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                autoComplete="off"
                                id="email"
                                className="form-control"
                                placeholder="Email"
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <span className="isexists" style={{color: 'red'}}></span>
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                id="password"
                                className="form-control"
                                placeholder="Password"
                                required
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                name="password_confirmation"
                                id="confirm_password"
                                className="form-control"
                                placeholder="Password Confirmation"
                                required
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <button 
                            type="button" 
                            className="btn btn-primary"
                            onClick={handleRegister}
                            disabled={isLoading}
                        >
                            <span style={ isLoading ? {} : { display: 'none' }} className="glyphicon glyphicon-refresh spinning" /> Register
                        </button>

                        <p><a className="Forgot" onClick={ () => navigate(Mode.FORGOTPASSWORD) }>Forgot Password?</a></p>
                        <p><a className="back" onClick={ () => navigate(Mode.LOGIN) }>Login</a></p>
                    </form>
                </div>
            </div>
        </div>
    )
})

const Login = () => {

    const [mode, setMode] = useState(Mode.LOGIN)
    const loginModalEl = useRef(null)

    const navigate = (mode) => {
        setMode(mode)
    }

    const onLogin = () => {
        window.closeLoginModal()
    }

    const onRegister = () => {
        window.closeLoginModal()
    }

    return (
        <div className="modal fade login-modal" id="loginModal" ref={loginModalEl} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog" role="document">
                { (mode === Mode.LOGIN) && <LoginForm navigate={navigate} onLogin={onLogin} />}
                { (mode === Mode.FORGOTPASSWORD) && <ForgotPasswordForm navigate={navigate} />}
                { (mode === Mode.REGISTER) && <RegisterForm navigate={navigate} onRegister={onRegister} />}
            </div>
        </div>        
    )
}

export default Login
