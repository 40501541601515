import React from 'react'

const FirebaseContext = React.createContext(null)

// TODO: Switch to useContext: https://reactjs.org/docs/hooks-reference.html#usecontext
export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
)

export default FirebaseContext
