import React, { useContext, useEffect, useState } from 'react'
import { withFirebase } from '../Firebase'
import {
    useHistory,
    useParams,
} from "react-router-dom"
import Helmet from 'react-helmet';
import { DateTime } from "luxon"
import { AuthUserContext } from '../Session'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Claim } from '../Claim'

const type = ({ claim }) => {
    switch (claim.claimType) {
    case 'autoWitness':
        return 'Auto Witness'
    case 'autoNonCatastrophe':
        return 'Auto Non Catastrophe'
    case 'autoEarthquakeCatastrophe':
        return 'Auto Earthquake Catastrophe'
    case 'autoFireCatastrophe':
        return 'Auto Fire Catastrophe'
    case 'autoFloodCatastrophe':
        return 'Auto Flood Catastrophe'
    case 'autoHailCatastrophe':
        return 'Auto Hail Catastrophe'
    case 'autoHurricaneCatastrophe':
        return 'Auto Hurricane Catastrophe'
    case 'autoTornadoCatastrophe':
        return 'Auto Tornado Catastrophe'
    case 'businessWitness':
        return 'Business Witness'
    case 'businessNonCatastrophe':
        return 'Business Non Catastrophe'
    case 'businessEarthquakeCatastrophe':
        return 'Business Earthquake Catastrophe'
    case 'businessFireCatastrophe':
        return 'Business Fire Catastrophe'
    case 'businessFloodCatastrophe':
        return 'Business Flood Catastrophe'
    case 'businessHailCatastrophe':
        return 'Business Hail Catastrophe'
    case 'businessHurricaneCatastrophe':
        return 'Business Hurricane Catastrophe'
    case 'businessTornadoCatastrophe':
        return 'Business Tornado Catastrophe'
    case 'homeWitness':
        return 'Home Witness'
    case 'homeNonCatastrophe':
        return 'Home Non Catastrophe'
    case 'homeEarthquakeCatastrophe':
        return 'Home Earthquake Catastrophe'
    case 'homeFireCatastrophe':
        return 'Home Fire Catastrophe'
    case 'homeFloodCatastrophe':
        return 'Home Flood Catastrophe'
    case 'homeHailCatastrophe':
        return 'Home Hail Catastrophe'
    case 'homeHurricaneCatastrophe':
        return 'Home Hurricane Catastrophe'
    case 'homeTornadoCatastrophe':
        return 'Home Tornado Catastrophe'
    default:
        return ''
    }
}

const imageDescription = ({ image }) => {
    switch (image.mediaSubject) {
        case 'damageInsideHome':
            return 'Damage Inside Home'
        case 'damageOutsideHome':
            return 'Damage Outside Home'
        case 'frontOfHome':
            return 'Front Of Home'
        case 'itemsInsideHome':
            return 'Items Inside Home'
        default:
            return ''
    }
}


const Purchase = ({ firebase }) => {

    const history = useHistory()
    // const [claim, setClaim] = useState(history.location.state?.claim)
    const { claimId } = useParams()
    const [claim, setClaim] = useState(null)
    useEffect(() => {
        console.log('claimId', claimId)
        // const claim = await firebase.searchClaims({ id })
        // const claim = await firebase.load({ purchase })
        
        firebase
            .load({ claimId })
            .then(claim => {
                console.log('claim', claim)
                setClaim(claim)
            })
            .catch(error => {
                console.warn(error)
            });
        // history.push("/claims/view", { claim })
    }, [])

    if (claim === null) {
        return null
    }

    const date = DateTime.fromSeconds(claim.date.seconds || claim.date._seconds || 0).toFormat('MMMM dd, yyyy')
    const time = DateTime.fromSeconds(claim.date.seconds || claim.date._seconds || 0).toFormat('H:mm a')

    const videos = claim.videos.length
        ? claim.videos.map((video, index) => {
            return (
                <a href={video.url}>Video {index + 1}</a>
            )
        })
        : 'None.'                            


    const maps = [8, 12, 16].map(zoom => {
        const firstImage = claim.images[0]
        const { latitude, longitude } = firstImage.location
        // const location = `Location: ${Number(latitude.toFixed(5))},${Number(longitude.toFixed(5))}`

        // TODO: This is Street FC's, remove!!!
        const key = 'AIzaSyCOkw0ajIQzEJtexGbXFZSOVXCvc3NQ534'
        const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=${latitude},${longitude}&size=96x128&key=${key}`
        // `${mapUrl}&zoom=8`

        // https://www.google.com/maps/search/?api=1&query=13.0106708,77.5552532
        // ,z${zoom}


        return (
            <div key={`map-${zoom}`} className="single-map">
                <h4>{`${Number(latitude.toFixed(5))},${Number(longitude.toFixed(5))}`}</h4>
                <a href={`https://www.google.com/maps/search/?api=1&q=${latitude},${longitude}`}><img src={`${mapUrl}&zoom=${zoom}`} alt="" /></a>
            </div>
        )
    })

    const firstImage = claim.images[0];
    const { latitude, longitude } = firstImage.location;


    return (
        <>
            <div className="section header-section">
                <div className="row row-top">
                    <div className="container">
                        <div className="cell">
                            <b>Accident Report</b>
                        </div>
                        <div className="cell flex-end flex-middle">
                            <div className="icon">
                                <img src={require('./assets/report.svg')} alt="" />
                            </div>
                            <b>Report Number</b>: {claim.id}
                        </div>
                    </div>
                </div>
                <div className="row row-middle">
                    <div className="container">
                        <a href="#"><img src="/images/logo_blue.png" alt="" /></a>
                    </div>
                </div>
            </div>

            
            <div className="section info-section">
                <div className="row">
                    <div className="container narrow">
                        <div className="infos">
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/claim.svg')} alt="" /></div>Claim Creator</h4>
                                <h5>{claim.user.name}</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/address.svg')} alt="" /></div>Address</h4>
                                <h5>{claim.user.address}</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/insurance.svg')} alt="" /></div>Insurance Co</h4>
                                <h5>Claimkey</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/date.svg')} alt="" /></div>Date</h4>
                                <h5>{date}</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/time.svg')} alt="" /></div>Time</h4>
                                <h5>{time}</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/phone.svg')} alt="" /></div>Phone Number</h4>
                                <h5>{claim.user.phoneNumber}</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/weather.svg')} alt="" /></div>Weather</h4>
                                <h5>Sunny</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/type.svg')} alt="" /></div>Type</h4>
                                <h5>{type({ claim })}</h5>
                            </div>
                            <div className="single-info">
                                <h4><div className="icon"><img src={require('./assets/long.svg')} alt="" /></div>Long / Lat</h4>
                                <h5>{Number(longitude.toFixed(5))} / {Number(latitude.toFixed(5))}</h5>
                            </div>
                        </div>
                        <div className="space-35"></div>
                        <div className="line"></div>
                    </div>
                </div>
            </div>
            

            
            <div className="section map-section">
                <div className="space-35"></div>
                <div className="row">
                    <div className="container narrow">
                        <div className="left-cell">
                            <h4><div className="icon"><img src={require('./assets/claim.svg')} alt="" /></div>Photos</h4>
                            <div className="gallery">
                                {claim.images.map((image, index) => {
                                    return (
                                        <div key={`image-${index}`} className="single-gallery">
                                            <h4>{imageDescription({ image })}</h4>
                                            <img src={image.url} alt="" />
                                        </div>    
                                    )
                                })}
                            </div>
                        </div>
                        <div className="right-cell">
                            <h4><div className="icon"><img src={require('./assets/claim.svg')} alt="" /></div>Video Links</h4>
                            <div className="space-25"></div>
                            {videos}
                            <div className="space-25"></div>
                            <h4><div className="icon"><img src={require('./assets/map.svg')} alt="" /></div>Maps</h4>
                            <div className="maps">
                                {maps}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="section buttons-section">
                <div className="space-20"></div>
                <div className="row">
                    <div className="container">
                        <div className="buttons">
                            <a href="#" className="button">Feedback</a>
                            <a href="#" className="button outline">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
                
            
            <div className="space-20"></div>
            <div className="section footer-section">
                <div className="row">
                    <div className="container">
                        2022 Claimkey. All Rights Reserved
                    </div>
                </div>
            </div>
            
        </>
    )



    return (
        <>
            <Helmet
                link={[
                    {"rel": "stylesheet", type:"text/css", "href": "/template/stylesheets.css"}
                ]}
            />
            {/* <p className="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p> */}
            
            <div className="section header-section">
            <div className="row row-top">
                <div className="container">
                    <div className="cell">
                        <b>Accident Report</b>
                    </div>
                    <div className="cell flex-end flex-middle">
                        <div className="icon">
                            <img src={require('./assets/report.svg')} alt="" />
                        </div>
                        <b>Report Number</b>: {claim.id}
                    </div>
                </div>
            </div>
            <div className="row row-middle">
                <div className="container">
                    <a href="#"><img src={require('./assets/logo.png')} alt="" /></a>
                </div>
            </div>
            </div>

            
            <div className="section info-section">
            <div className="row">
                <div className="container narrow">
                    <div className="infos">
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/claim.svg')} alt="" /></div>Claim Creator</h4>
                            <h5>{claim.user.name}</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/address.svg')} alt="" /></div>Address</h4>
                            <h5>{claim.user.address}</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/insurance.svg')} alt="" /></div>Insurance Co</h4>
                            <h5>Claimkey</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/date.svg')} alt="" /></div>Date</h4>
                            <h5>2-3-2021</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/time.svg')} alt="" /></div>Time</h4>
                            <h5>12 : 30 PM</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/phone.svg')} alt="" /></div>Phone Number</h4>
                            <h5>01010101</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/weather.svg')} alt="" /></div>Weather</h4>
                            <h5>Sunny</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/type.svg')} alt="" /></div>Type</h4>
                            <h5>Home Non Catastrophe</h5>
                        </div>
                        <div className="single-info">
                            <h4><div className="icon"><img src={require('./assets/long.svg')} alt="" /></div>Long / Lat</h4>
                            <h5>30000/30000</h5>
                        </div>
                    </div>
                    <div className="space-35"></div>
                    <div className="line"></div>
                </div>
            </div>
            </div>

            <div className="section map-section">
            <div className="space-35"></div>
            <div className="row">
                <div className="container narrow">
                    <div className="left-cell">
                        <h4><div className="icon"><img src={require('./assets/claim.svg')} alt="" /></div>Photos</h4>
                        <div className="gallery">
                            <div className="single-gallery">
                                <h4>Front of Home</h4>
                                <img src="image-1.png" alt="" />
                            </div>
                            <div className="single-gallery">
                                <h4>Damage Inside Home</h4>
                                <img src="image-2.png" alt="" />
                            </div>
                            <div className="single-gallery">
                                <h4>Items Inside Home</h4>
                                <img src="image-3.png" alt="" />
                            </div>
                            <div className="single-gallery">
                                <h4>Damage Outside Home</h4>
                                <img src="image-4.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="right-cell">
                        <h4><div className="icon"><img src={require('./assets/claim.svg')} alt="" /></div>Video Link</h4>
                        <div className="space-25"></div>
                        <a href="www.loremLipsum.com">www.loremLipsum.com</a>
                        <div className="space-25"></div>
                        <h4><div className="icon"><img src={require('./assets/map.svg')} alt="" /></div>Maps</h4>
                        <div className="maps">
                            <div className="single-map">
                                <h4>30.22654,-97.80627</h4>
                                <img src="map-1.png" alt="" />
                            </div>
                            <div className="single-map">
                                <h4>30.22654,-97.80627</h4>
                                <img src="map-2.png" alt="" />
                            </div>
                            <div className="single-map">
                                <h4>30.22654,-97.80627</h4>
                                <img src="map-3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="section buttons-section">
            <div className="space-20"></div>
            <div className="row">
                <div className="container">
                    <div className="buttons">
                        <a href="#" className="button">Feedback</a>
                        <a href="#" className="button outline">Contact Us</a>
                    </div>
                </div>
            </div>
            </div>
            
            <div className="space-20"></div>
            <div className="section footer-section">
            <div className="row">
                <div className="container">
                    2022 Claimkey. All Rights Reserved
                </div>
            </div>
            </div>
        </>
    )
}

export default withFirebase(Purchase)
