import React from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer'

export default () => 
    <>
        <Header theme="WHITE" />
        <section className="testimonials-main">
            <div className="row">
                <div className="container">
                    <div className="testimonials-box">
                        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                                <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                                <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                                <li data-target="#carousel-example-generic" data-slide-to="3"></li>
                                <li data-target="#carousel-example-generic" data-slide-to="4"></li>
                                <li data-target="#carousel-example-generic" data-slide-to="5"></li>
                            </ol>
                            <div className="carousel-inner" role="listbox">
                                <div className="item active">
                                    <div className="carousel-caption">
                                        <div className="media">
                                            <div className="media-body">
                                                <p>During my accident I didn’t know what to do, but a friend told me about the Claimkey app and it was a total life saver.</p>      
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="carousel-caption">
                                        <div className="media">
                                            <div className="media-body">
                                                <p>My mind was racing during my accident, but I used the Claimkey during this stressful situation. I definitely felt at ease after I submitted my claim from the app.</p>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="carousel-caption">
                                        <div className="media">
                                            <div className="media-body">
                                                <p>I’ve done a claim before after an accident. It was a lot of work and time consuming. I downloaded the Claimkey app and it told me the exact information that I need to give to my adjuster, which helped to resolve my claim faster.</p>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="carousel-caption">
                                        <div className="media">
                                            <div className="media-body">
                                                <p>With Claimkey, it felt like my adjuster was at the scene with me. Everyone should download this app today!</p>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="carousel-caption">
                                        <div className="media">
                                            <div className="media-body">
                                                <p>Claimkey simplified sending information to my insurance agent, when my home was flooded.</p>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="carousel-caption">
                                        <div className="media">
                                            <div className="media-body">
                                                <p>There’s no guess work, you pull out your phone take some pictures, get a little information and you submit a claim. The best part is, you get to tell your side of the story.</p>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    </>
