import React, { 
    useContext, 
    useState
} from 'react'
import {
    useHistory,
} from "react-router-dom"
import { 
    CardElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js'
import { withFirebase } from '../Firebase'
import { AuthUserContext } from '../Session'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Claim } from '../Claim'

const PaymentForm = withFirebase(({ firebase, claims, onSuccess }) => {
    const stripe = useStripe()
    const elements = useElements()

    const authUser = useContext(AuthUserContext)

    const [email, setEmail] = useState(authUser ? authUser.email : '')
    const [isLoading, setIsLoading] = useState(false)

    const onPay = async (event) => {
        event.preventDefault()
    
        if (!stripe || !elements) {
            return
        }

        try {
            if (email.length === 0) {
                throw Error('Please provide an email for delivery of report.')
            }
    
            setIsLoading(true)

            const card = elements.getElement(CardElement)
            const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card })
            
            if (error) {
                console.error(error)
                throw error.message
            }
    
            await firebase.createPayment({
                claims,
                email,
                paymentMethod,
                onComplete: () => {
                    setIsLoading(false)
                    onSuccess({ email })
                },
            })
        } catch (error) {
            setIsLoading(false)
            alert(error)
        }
    }

    return (
        <div className="credit-card-form">
            <p>Safe money transfer using your bank account.<br />Visa, Mastercard, Discover, American Express</p>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <input
                        type="text" 
                        placeholder="Email address (for delivery)" 
                        // autoComplete='off'
                        className='form-control card-number' 
                        //maxlength='20'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form-group">
                <CardElement
                    options={{
                        style: {
                            base: {
                                // fontFamily: 'BrandonText-Regular',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '20px',
                    //             color: '#424770',
                    //             // color: '#00ff00',
                    //             '::placeholder': {
                    //                 color: '#aab7c4',
                    //             },
                            },
                    //         invalid: {
                    //             color: '#9e2146',
                    //         },
                        },
                    }}
                    style={{
                        // base: {
                            fontSize: '16px',
                            // fontFamily: '"Lato", Helvetica, sans-serif',
                            // fontFamily: 'BrandonText-Regular',
                            fontFamily: 'Montserrat, sans-serif',
                            // border: 1,
                            // borderColor: 'red',
                        // }
                    }}
                    // options={{
                    //     fonts: [
                    //         {
                    //             src: 'https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2',
                    //             family: 'Lato',
                    //             style: 'normal',
                    //         }
                    //     ]
                    // }}
                />
                </div>
            </div>

            {/* <div className="col-md-12 col-sm-12">
                <div className="form-group">
                    <input type="text" placeholder="Credit Card Number" autoComplete='off' className='form-control card-number' maxlength='20' />
                </div>
            </div>
            <div className="col-md-6 col-sm-6">
                <div className='col-xs-12 col-md-4 form-group expiration required'>
                    <input className='form-control card-expiry-month' placeholder='MM' maxlength='2' type='text' />
                </div>
                <div className='col-xs-12 col-md-4 form-group expiration required'>
                    <input className='form-control card-expiry-year' placeholder='YYYY' maxlength='4' type='text' />
                </div>
            </div>
            <div className="col-md-6 col-sm-6">
                <div className="form-group">
                    <input type="text" placeholder="CVV" autoComplete='off' className='form-control card-cvc' placeholder='ex. 311' size='4' />
                    <input type="hidden" name="docId" value="20A78177-2C99-4C7F-90BF-B53934F73F7F" />
                </div>
            </div> */}

            <div className="clearfix"></div>
            <button 
                type="button" 
                className="btn btn-primary"
                onClick={onPay}
                disabled={!stripe || isLoading}
            >
                <span style={ isLoading ? {} : { display: 'none' }} className="glyphicon glyphicon-refresh spinning" /> Pay ${claims.length * 24.99}
            </button>
        </div>
    )
})

const Success = ({ email }) =>
    <div className="credit-card-form">
        <p>Check {email} for your purchased claim.</p>
    </div>

const Payment = () => {

    const history = useHistory()
    const [claims] = useState(history.location.state?.claims ?? [])
    const [success, setSuccess] = useState(false)
    const [email, setEmail] = useState('')

    const onSuccess = ({ email }) => {
        setSuccess(true)
        setEmail(email)
    }

    return (
        <>
            <Header theme="WHITE" />
            <section className="signup-main">
                <div className="row">
                    <div className="container">
                        <div className="col-md-12 col-sm-12">
                            <h2>{ !success ? "Purchase Claim" : "Payment Successful"}</h2>
                        </div>
                        <div className="payment-main">
                            <div className="col-md-8 col-sm-8">
                                <div className="payment-box">
                                    <div className="payment-tab">
                                        {/* <div className="payment-btn">
                                            <div className="radio credit-card">
                                                <input id="radio-1" name="radio" type="radio" checked />
                                                <label for="radio-1" className="radio-label">Credit Card</label>
                                            </div>
                                        </div> */}
                                        { !success ?
                                            <PaymentForm
                                                claims={claims}
                                                onSuccess={onSuccess}
                                            />
                                            :
                                            <Success
                                                email={email}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            { claims.map(claim => <Claim key={claim.id} claim={claim} />) }
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default Payment
