import React from 'react'
import AuthUserContext from './context'
import { withFirebase } from '../Firebase'

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {

        constructor(props) {
            super(props)
            this.state = {
                authUser: null,
            }
        }

        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    authUser
                    ? this.setState({ authUser })
                    : this.setState({ authUser: null })
                },
            )
        }

        componentWillUnmount() {
            this.listener()
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            )
        }
    }

    return withFirebase(WithAuthentication)
}

export default withAuthentication


// import React, { useState } from 'react'
// import AuthUserContext from './context'
// import { withFirebase } from '../Firebase'

// // export const withAuthentication = Component => props => (
// const withAuthentication = Component => props => {

//     const user = { name: 'rob' }

//     // return <Component {...props} user={user} />

//     return (
//         <AuthUserContext.Provider>
//             {user => <Component {...props} user={user} />}
//         </AuthUserContext.Provider>
//     )
// }

// function withAuthentication(params) {
//     return <h1>hello</h1>
// }

// function withAuthentication(Wrapped) {
//     return function (props) {
//        //const [count, setCount] = useState(0);
   
//        //props['count'] = count;
//        // props['setCount'] = setCount;
//        // return <Wrapped {...props} />;
//        return <Wrapped {...props}></Wrapped>;
//     //    return <h1>hello</h1>
//     }
//  }

// const withAuthentication = Component => {
//     class WithAuthentication extends React.Component {
//         constructor(props) {
//             super(props)

//             this.state = {
//                 authUser: null,
//             }
//         }

//         componentDidMount() {
//             this.listener = this.props.firebase.auth.onAuthStateChanged(
//                 authUser => {
//                     authUser
//                         ? this.setState({ authUser })
//                         : this.setState({ authUser: null })
//                     },
//             )
//         }

//         componentWillUnmount() {
//             this.listener()
//         }

//         render() {
//             return (
//                 <AuthUserContext.Provider value={this.state.authUser}>
//                     <Component {...this.props} />
//                 </AuthUserContext.Provider>
//             )
//         }
//     }

//     return withFirebase(WithAuthentication)
// }

// export default withFirebase(withAuthentication)
// export default withAuthentication
